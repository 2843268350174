import React from 'react';
import Slider from '../components/Slider';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Landing from '../components/Landing';
import HomeLayout from 'Layout/home';
import useAuth from '@hooks/useAuth';
import HomeNew from '@components/LandingPage';

export default function Home() {
  const { loading, user } = useAuth();

  return (
    <div className="continer-fluid">
      <HomeLayout>
        {/* <Header
          bg={"bg-[#68c1b8]"}
          textOff={"!text-[#fff]"}
          title={"!text-[#fff]"}
          signupBg={"!bg-[#fff]"}
          signupText={"!text-[#68c1b8]"}
        /> */}
        {/* <Hero />
        <Landing /> */}
        <HomeNew />
      </HomeLayout>
    </div>
  );
}
