/* eslint-disable @next/next/no-img-element */
import React from 'react';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Image from 'next/image';
// import Slider from 'react-slick';
import Marquee from 'react-fast-marquee';

const carouselItems = [
  {
    id: 1,
    image: '/videos/1.mp4',
    category: "Travel",
    name: "Jamie Lee"
  },
  {
    id: 2,
    image: '/videos/2.mp4',
    category: "Health & Wellness",
    name: "Avery Katherine"
  },
  {
    id: 3,
    image: '/videos/3.mp4',
    category: "Beauty",
    name: "Shrima Rai"
  },
  {
    id: 4,
    image: '/videos/4.mp4',
    category: "Fitness",
    name: "Christina Ronaldo"
  },
  {
    id: 5,
    image: '/videos/5.mp4',
    category: "Health & Wellness",
    name: "Dakota Nguyen"
  },
  {
    id: 6,
    image: '/videos/6.mp4',
    category: "Technology",
    name: "Shrima Rai"
  },
  {
    id: 7,
    image: '/videos/7.mp4',
    category: "Business & Finance",
    name: "Cassidy Lynn"
  }
];

const OurWorks = () => {
  // const settings = {
  //   autoplay: true,
  //   className: 'center',
  //   infinite: true,
  //   // centerMode: true,
  //   centerPadding: '60px',
  //   slidesToShow: 5,
  //   speed: 200,
  //   slidesPerRow: 1,
  //   arrows: false,
  //   responsive: [
  //     {
  //       breakpoint: 1250,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 900,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <div className="w-full">
      <div className="our_work_title text-center [font-family:'Quicksand',Helvetica] font-bold text-[#68c1b8] text-xl tracking-[0] leading-[24px] whitespace-nowrap mb-2 relative after:absolute content-[' '] after:right-[25%] xs:after:right-[10%] sm:after:right-[30%] lg:after:right-[43%] after:top-3 after:w-10 after:h-[3px] after:bg-[#68C1B8] before:absolute content-[' '] before:left-[25%] xs:before:left-[10%]  sm:before:left-[30%] lg:before:left-[43%]  before:top-3 before:w-10 before:h-[3px] before:bg-[#68C1B8]">
        Our Success
      </div>
      <div className="[font-family:'Gilroy-Bold-☞',Helvetica] font-normal text-[#232323] lg:text-[36px] text-[30px] text-center tracking-[0] lg:leading-[55.2px] mb-2">
        Showcase of Elite Campaigns
      </div>
      <Marquee speed={100}  >
        {carouselItems && carouselItems.length > 0
          ? carouselItems.map((product) => {
              return (
                <>
                  <div id="featuredProducts" className="mx-2  my-4" key={product.id}>
                    <div className="productListing w-full relative">
                      {/* <img
                        className="w-full h-[500px] lg:h-[543px] rounded-xl"
                        id={'img' + product.id}
                        src={product.image}
                        alt={product.name}
                      /> */}
                      <video
                        controls
                        autoPlay
                        width="384px"
                        height="543px"
                       
                        className="lazypreload lazyloaded"
                        data-autoplay=""
                        preload="auto"
                        loop
                        muted
                        src={product.image}
                        data-expand="-10"
                      ></video>
                      <div className="w-full absolute left-0 right-0 bottom-0 sm:bottom-36 bg-[#0000001A] backdrop-blur-md p-2 text-center  rounded-bl-xl rounded-br-xl overflow-hidden">
                        <h2 className="text-xl text-white font-bold capitalize">{product.name}</h2>
                        <p className="text-xl  text-white font-normal capitalize">
                          {product.category}
                        </p>
                        <ul className="flex items-center justify-center gap-2 ">
                          <li>
                            <a href="#">
                              <img className="w-9 h-9" src="./icons/instagram.png" alt="instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img className="w-9 h-9" src="./icons/snapchat.png" alt="snapchat" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <img className="w-9 h-9" src="./icons/tiktok.png" alt="tiktok" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          : ''}
      </Marquee>
    </div>
  );
};
export default OurWorks;