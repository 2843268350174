/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import dynamic from 'next/dynamic';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
declare const window: any;

const carouselItems = [
  {
    id: 1,
    image: '/brands/brand-1.png',
    logo: '/brands/brand-logo-1.png',
    brand: 'Target',
    brandDescription: '$6.8M Paid to Creators',
    title: 'Weekly Highlighhts: Home & Outdoors',
    description: '7% Commission Rate',
  },
  {
    id: 2,
    image: '/brands/brand-2.png',
    logo: '/brands/brand-logo-2.png',
    brand: 'Nike',
    brandDescription: '$6.8M Paid to Creators',
    title: 'Always On',
    description: '25% Commission Rate',
  },
  {
    id: 3,
    image: '/brands/brand-3.png',
    logo: '/brands/brand-logo-3.png',
    brand: 'Tyson',
    brandDescription: '$6.8M Paid to Creators',
    title: 'Weekly Highlights: Fashion Mania',
    description: '7% Commission Rate',
  },
  {
    id: 4,
    image: '/brands/brand-4.png',
    logo: '/brands/brand-logo-4.png',
    brand: 'Yamaha',
    brandDescription: '$6.8M Paid to Creators',
    title: 'Always On',
    description: '15% Commission Rate.',
  },
];

var $ = require('jquery');
if (typeof window !== 'undefined') {
  window.$ = window.jQuery = require('jquery');
}

// This is for Next.js. On Rect JS remove this line
const OwlCarousel = dynamic(() => import('react-owl-carousel'), {
  ssr: false,
});

// This is for React JS, Remove this for Next.js
// import OwlCarousel from 'react-owl-carousel';

const BrandCampaign = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    navClass: ['owl-prev', 'owl-next'],
    navText: ['<i class="fa-solid fa-arrow-left"></i>', '<i class="fa-solid fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <div className="creative_potential_two relative row mb-16 sm:mb-20  2xl:px-0">
      <div className=" col-12 pb-2 bg-white" id="owl-carousel-products">
        <div className="lg-mb-[30px] mb-6">
          <div className=" ps-4 text-[24px] lg:text-[36px] font-['Gilroy-Bold-☞'] lg:leading-[55.2px] capitalize text-[#232323]">
            Top Brand Campaigns
          </div>
        </div>
        <ul
          id="owl-carousel-ul"
          className="owl-carousel owl-loaded owl-drag"
          style={{ margin: '0!important', padding: 0 }}
        >
          <OwlCarousel
            className="owl-theme"
            loop
            // margin={4}
            // nav={true}
            // navText={['<img src="/images/Arrow_left.png" />', '<img src="/images/Arrow_right.png" />']}
            // dots={false}
            animateIn={true}
            {...options}
          >
            {carouselItems && carouselItems.length > 0
              ? carouselItems.map((product) => {
                  return (
                    <>
                      <div
                        className="item float-left w-full h-[340px] mb-2 rounded-[20px] shadow-brandCampaign"
                        key={product.id}
                      >
                        <div className="productListing w-full">
                          <img
                            className="w-full max-h-[188px]"
                            id={'img' + product.id}
                            src={product.image}
                            // alt={product.id}
                          />
                          <div className="flex content-between h-[130px]  row w-100 ps-3 mt-2  mb-1">
                            <div className="text-capitalize [font-family:'Quicksand',Helvetica] font-bold text-black text-[20px] ">
                              {product.title}
                            </div>
                            <div className="[font-family:'Quicksand',Helvetica] font-normal text-black text-[16px] tracking-[0] leading-[24px] itemPrice px-[12]">
                              {product.description}
                            </div>
                            <div className="flex mt-2">
                              <img src={product.logo} className="rounded-full !w-[42px] h-[42px] object-cover " />
                              <div className="flex flex-col ml-2">
                                <div
                                  id="InfluencersRoot"
                                  style={{ fontSize: '16px' }}
                                  className="font-['Quicksand'] !text-base !font-medium text-black"
                                >
                                  {product.brand}
                                </div>
                                <div
                                  id="InfluencersRoot"
                                  className="!text-[12px] font-['Quicksand'] leading-[18px] !font-normal"
                                >
                                  {product.brandDescription}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              : ''}
          </OwlCarousel>
        </ul>
      </div>
    </div>
  );
};
export default BrandCampaign;
