import React from 'react';
import OurGallery from './OurGallery';
import InfluencerMarketingPotentialTwo from './InfluencerMarketingPotentialTwo';
import CreativePotentialSliderTwo from './CreativePotentialSliderTwo';
import OurProducts from './OurProducts';
import BrandCampaign from './BrandCampaign';
import OurWorks from './OurWorks';
import HeroTwo from './HeroTwo';
import FeaturedListingTwo from './FeaturedListingTwo';

const HomeNew = () => {
  return (
    <div>
      <HeroTwo />
      {/* <div className="mx-auto max-w-8xl px-5 sm:px-6 lg:px-8 "> */}

      <div className="md:max-w-[1440px] lg:xl:max-w-7xl mx-auto w-full ps-10 md:lg:xl:ps-0 pe-10 md:lg:xl:pe-0   pt-12">
        <BrandCampaign />
      </div>
      <div className="bg-[#EDF4F7] pt-12 pb-5">
        <div className="max-w-[1900px] w-full mx-auto">
          <OurWorks />
        </div>
      </div>
      {/*<div className="bg-white  max-w-[1350px] mx-auto lg:xl:max-w-7xl">*/}
      {/*  <FeaturedListingTwo />*/}
      {/*</div>*/}
      <OurGallery />
      <div className="bg-white  ps-10 md:lg:xl:ps-0 pe-10 md:lg:xl:pe-0    max-w-7xl mr-auto ml-auto">
        {/*<InfluencerMarketingPotentialTwo />*/}
        <OurProducts />
        {/*<CreativePotentialSliderTwo />*/}
      </div>
    </div>
  );
};

export default HomeNew;
