/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import React from 'react';

const OurProducts = () => {
  return (
    <div className="lg:mb-20 mb-16 lg:mt-16 mt-20">
      <div className="our_work_title text-center [font-family:'Quicksand',Helvetica] font-bold text-[#68c1b8] text-xl tracking-[0] leading-[24px] whitespace-nowrap mb-2 relative after:absolute content-[' '] after:right-[5%] xs:after:right-[10%] sm:after:right-[30%] lg:after:right-[40%] after:top-3 after:w-10 after:h-[3px] after:bg-[#68C1B8] before:absolute content-[' '] before:left-[5%] xs:before:left-[10%]  sm:before:left-[30%] lg:before:left-[40%]  before:top-3 before:w-10 before:h-[3px] before:bg-[#68C1B8]">
        Our Categories
      </div>
      <div className="[font-family:'Gilroy-Bold-☞',Helvetica] font-normal text-[#232323] lg:text-[46px] text-[30px] text-center tracking-[0] lg:leading-[55.2px] mb-10 ">
        Top Influencers By Category
      </div>
      <div className="grid grid-cols-12  gap-3 ">
        <div className="col-span-full sm:col-span-5">
          <img
            className="w-full max-h-[495px] h-full rounded-xl cursor-pointer"
            src="/our-products/product-7.jpg"
            alt="Product item"
          />
        </div>
        {/*<div className="col-span-full xs:col-span-8 sm:col-span-4">*/}
        {/*  <div className="grid grid-cols-2  gap-3">*/}
        {/*    <div className="col-span-full">*/}
        {/*      <img*/}
        {/*        className="w-full max-h-[219px] h-full rounded-xl cursor-pointer"*/}
        {/*        src="/our-products/product-8.jpeg"*/}
        {/*        alt="Product item"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="col-span-1">*/}
        {/*      <img*/}
        {/*        className="w-full max-h-[255px] h-full  rounded-xl cursor-pointer"*/}
        {/*        src="/our-products/product-9.webp"*/}
        {/*        alt="Product item"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="col-span-1 ">*/}
        {/*      <img*/}
        {/*        className="w-full max-h-[255px] h-full  rounded-xl cursor-pointer"*/}
        {/*        src="/our-products/product-4.webp"*/}
        {/*        alt="Product item"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
          <div className="col-span-full xs:col-span-8 sm:col-span-4">
              <div className="grid grid-cols-2 gap-3">
                  <div className="col-span-2">
                      <img
                          className="w-full rounded-xl cursor-pointer aspect-auto"
                          src="/our-products/product-8.jpeg"
                          alt="Product item"
                      />
                  </div>
                  <div className="col-span-1">
                      <img
                          className="w-full rounded-xl cursor-pointer aspect-auto"
                          src="/our-products/product-2.webp"
                          alt="Product item"
                      />
                  </div>
                  <div className="col-span-1">
                      <img
                          className="w-full rounded-xl cursor-pointer aspect-auto"
                          src="/our-products/product-3.webp"
                          alt="Product item"
                      />
                  </div>
              </div>
          </div>

          <div className="col-span-full xs:col-span-4 sm:col-span-3 bg-slate-500 rounded-xl overflow-hidden">
          <img
              className="w-full object-cover rounded-xl cursor-pointer"
              src="/our-products/product-10.jpg"
              alt="Product item"
          />
        </div>
      </div>
    </div>
  );
};
export default OurProducts;
