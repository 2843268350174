/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import Link from 'next/link';
import routes from 'routes';

const HeroTwo = () => {
  return (
    <div className="bg-[#68c1b8] lg:ps-10 lg:pr-24 flex">
      <div className="max-w-[1440px] w-full mx-auto   inline-flex items-center justify-around relative">
        <div className="gap-[34px] px-8 md:px-8 lg:px-0  inline-flex flex-col items-start justify-center relative lg:mt-16 lg:mb-16 md:mt-12 md:mb-12 sm:pt-8 sm:pb-8 pt-8 pb-8">
          <div className="gap-[6px] inline-flex flex-col items-start justify-center relative ">
            <p className="w-full lg:w-[544px] mt-[-1.00px] [font-family:'Quicksand',Helvetica] text-white text-[40px] md:text-[44px] lg:text-[56px] relative font-semibold tracking-[0]">
              Unleash Brand Potential with Content Studio
            </p>
            <p className="w-full lg:w-[540px] [font-family:'Quicksand',Helvetica] text-white text-[16px] leading-[24px] relative font-thin tracking-[0]">
              Professional Content Creation Tailored for Your Business, Ready to Skyrocket Engagement and ROI.
            </p>
          </div>
          <div className="inline-flex items-center gap-[21px] relative flex-[0_0_auto]">
            {/*<div className="px-[20px] py-[10px] bg-white inline-flex items-center justify-center gap-[10px] relative flex-[0_0_auto] rounded-md">*/}
            {/*  <div className="w-fit mt-[-1.00px] [font-family:'Quicksand',Helvetica] text-[#68c1b8] text-[16px] leading-[24px] whitespace-nowrap relative font-normal tracking-[0]">*/}
            {/*    Learn More*/}
            {/*  </div>*/}
            {/*</div>*/}
            <Link href={routes.SIGNUP} passHref>
              <button
                className="px-[32px] py-[10px] border border-solid border-white inline-flex items-center justify-center gap-[10px] relative flex-[0_0_auto] rounded-md"
                style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }} // Style adjustments for button
              >
                <div className="w-fit mt-[-1.00px] [font-family:'Quicksand',Helvetica] text-white text-[16px] leading-[24px] whitespace-nowrap relative font-normal tracking-[0]">
                  Join Now
                </div>
              </button>
            </Link>
          </div>
        </div>
        <div className="w-[45%]  justify-center hidden lg:flex py-10">
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-6 ">
              <div className="img bg-white   rounded-tl-full relative">
                <img className=" w-full" src="/hero/hero-1.png" alt="hero" />
                <div className="flex top-20 absolute md:-left-60 lg:xl:-left-32 bg-white px-2 py-3 rounded-md">
                  <img src="./brands/brand-logo-2.png" className="rounded-full !w-[42px] h-[42px] object-cover " />
                  <div className="flex flex-col ml-2">
                    <div id="InfluencersRoot" className="font-['Quicksand'] text-sm !font-bold text-black">
                      Get Ready for <br /> Viral Campaign
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-6">
              <div className="img bg-white p-0.5 relative">
                <img className="max-w[242px] w-full" src="/hero/hero-2.png" alt="hero" />
                <div className="flex bottom-16 absolute -right-8  bg-white px-3 py-2 rounded-full">
                  <img src="./brands/brand-logo-5.png" className="rounded-full !w-[42px] h-[42px] object-cover " />
                  <div className="flex flex-col  justify-center ml-2">
                    <div id="InfluencersRoot" className="font-['Quicksand'] text-sm !font-bold text-black">
                      5465+
                    </div>
                    <p className="text-xs text-[#1E2427] p-0 m-0">Total Campaigns</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-6 round relative">
              <div className="img w[242px]  bg-white p-0.5 rounded-bl-full relative">
                <img className="w-full" src="/hero/hero-3.png" alt="hero" />
                <div className="flex bottom-8 absolute -left-14  bg-white px-3 py-2 rounded-full">
                  <img src="./brands/heart.png" className="rounded-full !w-[42px] h-[42px] object-cover " />
                  <div className="flex flex-col  justify-center ml-2">
                    <div id="InfluencersRoot" className="font-['Quicksand'] text-sm !font-bold text-black">
                      99.99%
                    </div>
                    <p className="text-xs text-[#1E2427] p-0 m-0">Satisfied Users</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-6">
              <div className="img bg-white p-0.5 rounded-br-full relative">
                <img className="max-w[242px] w-full" src="/hero/hero-4.png" alt="hero" />
                <div className="flex sm:bottom-8 md:bottom-12  lg:xl:bottom-12 absolute md:-right-2 lg:xl:-right-20 bg-white px-2 py-2 rounded-md">
                  <img src="./avatar-6.png" className="rounded-full !w-[42px] h-[42px] object-cover " />
                  <div className="flex flex-col ml-2">
                    <div id="InfluencersRoot" className="font-['Quicksand'] text-sm !font-bold text-black">
                      Ronald Richards
                    </div>
                    <p className="text-xs text-[#1E2427] m-0 p-0">
                      Viralocity transformed <br /> my social media😍
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroTwo;
