/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import routes from 'routes';

const OurGallery = () => {
  return (
    <div className="w-full bg-[#F7F5ED] pt-7 px-2 lg:px-2 pb-12">
      <div className="max-w-[1820px] mx-auto">
        <div className="grid grid-cols-12 gap-4 items-center">
          <div className="col-span-12 sm:col-span-4 lg:col-span-3">
            <div className="relative h-full">
              <img className="sm:me-4 pt-4" src="./gallery/gallery-left-4.png" alt="Our gallery " />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-8 lg:col-span-6 bg-white shadow-gallery px-8 sm:px-10 lg:px-16 py-6 sm:py-9 rounded-md lg:mt-36">
            {/*<div className="our_work_title text-center font-bold text-[#68c1b8] text-xl tracking-[0] leading-[24px] whitespace-nowrap mb-2 relative">*/}
            {/*  Connect*/}
            {/*</div>*/}
            <div className="our_work_title text-center font-bold text-[#68c1b8] text-xl tracking-[0] leading-[24px] whitespace-nowrap mb-2 relative after:absolute content-[' '] after:right-[5%] xs:after:right-[10%] sm:after:right-[30%] after:top-3 after:w-10 after:h-[3px] after:bg-[#68C1B8] before:absolute content-[' '] before:left-[5%] xs:before:left-[10%] sm:before:left-[30%] before:top-3 before:w-10 before:h-[3px] before:bg-[#68C1B8]">
              Connect
            </div>
            <div className="font-normal text-[#282828] text-xl sm:text-2xl text-center tracking-[0] mb-1">
              Amplify Your Influence
            </div>
            <p className="font-normal text-[#6A6A6A] text-lg text-center lg:px-1 mb-4 sm:mb-6">
              Unleash Potential: Collaborate with Industry-Leading Influencers
            </p>
            <div className="text-center">
              <Link href={routes.ALL_LISTINGS} passHref>
                <button className="text-base font-sans font-bold text-white bg-[#68C1B8] rounded-lg text-center px-8 py-[10px]">
                  Discover more
                </button>
              </Link>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4 lg:col-span-3">
            <div className="relative h-full">
              <img className="sm:ms-0 pt-10" src="./gallery/gallery-right-2.png" alt="Our gallery " />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGallery;
