import FooterTwo from '@components/common/FooterTwo';
import Header from '@components/common/Header';

type Props = {
  children: React.ReactNode;
  navbar_transparent?: boolean;
  className?: string;
};

export default function homeTwo(props: Props) {
  return (
    <div className={`min-h-full  font-['Gilroy-Light'] ${props.className as string}`}>
      <Header bgTransparent={props.navbar_transparent} />
      <div className="min-vh-100">{props.children}</div>
      <FooterTwo />
    </div>
  );
}
